import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, skipWhile } from 'rxjs';
import { ContentType } from 'src/app/constants/interfaces';
import { appContent, VanguardStandardAccountGroups } from 'src/app/constants/template';
import { AccountData, viewData } from 'src/app/models/account-grouping.model';
import {
  AccountFormObject,
  CreateFormObject,
  HoldingFormObject,
} from 'src/app/models/create-group.model';
import { AccountGroupingDataService } from 'src/app/services/account-grouping-data/account-grouping-data.service';
import { PwViewsCreateService } from 'src/app/services/pw-views-create/pw-views-create.service';
import { PwViewsFormsService } from 'src/app/services/pw-views-forms/pw-views-forms.service';
import { PwViewsModifyService } from 'src/app/services/pw-views-modify/pw-views-modify.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-views-page-component',
  templateUrl: './views-page-component.component.html',
  styleUrls: ['./views-page-component.component.scss'],
})
export class ViewsPageComponentComponent implements OnInit {
  appContent: ContentType = appContent;
  investmentsList: AccountFormObject[] = [];
  hideEdit: boolean = true;
  viewMode: boolean = true;
  showCreationSuccessBanner: boolean = false;
  showModifySuccessBanner: boolean = false;
  showDeleteSuccessBanner: boolean = false;
  pwURL: string;
  portfolioWatchViews: viewData[] = [];
  portfolioWatchViews$: Observable<viewData[]>;
  activeAccountGroupViewId: string = '';

  createFormGroup: FormGroup;

  constructor(
    private pwViewsFormsService: PwViewsFormsService,
    private pwViewsCreateService: PwViewsCreateService,
    private accountGroupingDataService: AccountGroupingDataService,
    private pwViewsModifyService: PwViewsModifyService,
    private router: Router,
  ) {
    this.createFormGroup = new FormGroup({
      viewName: new FormControl(''),
      accounts: new FormArray([]),
    });
  }

  ngOnInit(): void {
    this.portfolioWatchViews$ = this.accountGroupingDataService.getPwViews();

    this.portfolioWatchViews$
      .pipe(skipWhile((accounts) => accounts.length === 0))
      .subscribe((viewsData: any) => {
        if (viewsData) {
          this.setupViewPageData(viewsData);
        }
      });

    this.showCreationSuccessBanner = this.pwViewsFormsService.showCreationSuccessBanner;
    this.showModifySuccessBanner = this.pwViewsFormsService.showModifySuccessBanner;
    this.showDeleteSuccessBanner = this.pwViewsFormsService.showDeleteSuccessBanner;
    if (
      [
        this.showCreationSuccessBanner,
        this.showModifySuccessBanner,
        this.showDeleteSuccessBanner,
      ].includes(true)
    ) {
      window.scrollTo({ top: 0, left: 0 });
    }
    this.pwURL = environment.portfolioWatchURL;
  }

  get investmentsFormArray(): any {
    return this.createFormGroup.get('accounts') as FormArray;
  }

  getAccountHoldings(acctIndex): FormArray {
    return this.investmentsFormArray.at(acctIndex).get('holdings') as FormArray;
  }

  setupViewPageData(viewsData): void {
    this.portfolioWatchViews = viewsData;
    this.activeAccountGroupViewId = this.getActiveAccountGroupViewId(viewsData);
    this.setupAccountsDataAndEditButtonVisibility(viewsData, this.activeAccountGroupViewId);
  }

  getActiveAccountGroupViewId(portfolioWatchViews: viewData[]): string {
    const lastCreatedAccountGroup: string = this.pwViewsCreateService.lastCreatedAccountGroup;
    const lastModifiedAccountGroup: string = this.pwViewsModifyService.lastModifiedAccountGroup;
    let acitveAccountGroupViewId: string | undefined;
    let allAccountsGroupViewId: string | undefined = portfolioWatchViews.find(
      (viewData) => viewData.viewName?.toLowerCase() === VanguardStandardAccountGroups.allAccounts,
    )?.clientViewId;
    if (this.pwViewsFormsService.showCreationSuccessBanner) {
      const lastCreatedAccountGroupViewId: string | undefined = portfolioWatchViews.find(
        (viewData) => viewData.viewName === lastCreatedAccountGroup,
      )?.clientViewId;

      acitveAccountGroupViewId = lastCreatedAccountGroupViewId
        ? lastCreatedAccountGroupViewId
        : allAccountsGroupViewId;
    } else if (this.pwViewsFormsService.showModifySuccessBanner) {
      const lastModifiedAccountGroupViewId: string | undefined = portfolioWatchViews.find(
        (viewData) => viewData.viewName === lastModifiedAccountGroup,
      )?.clientViewId;

      acitveAccountGroupViewId = lastModifiedAccountGroupViewId
        ? lastModifiedAccountGroupViewId
        : allAccountsGroupViewId;
    } else {
      acitveAccountGroupViewId = allAccountsGroupViewId;
    }
    return acitveAccountGroupViewId ? acitveAccountGroupViewId : '';
  }

  handleGroupSelection(event): void {
    this.resetSuccessBanners();
    const activeAccountGroupViewId: string = event.activeItems[0];
    this.activeAccountGroupViewId = activeAccountGroupViewId;
    this.setupAccountsDataAndEditButtonVisibility(
      this.portfolioWatchViews,
      activeAccountGroupViewId,
    );
  }

  setupAccountsDataAndEditButtonVisibility(
    viewsData: viewData[],
    activeAccountGroupViewId: string,
  ): void {
    const activeAccountGroupData: viewData =
      viewsData.find((viewData) => viewData?.clientViewId === activeAccountGroupViewId) ||
      ({ accounts: [] as AccountData[] } as viewData);
    const createObj: CreateFormObject = this.pwViewsFormsService.setupCreateObject(
      activeAccountGroupData.accounts,
    );
    this.investmentsList = createObj.accounts;
    this.hideEdit = activeAccountGroupData.groupType === 'STANDARD' || activeAccountGroupData.groupType === 'ACCOUNT' ? true : false;

    this.generateFormControls();
  }

  generateFormControls(): void {
    this.investmentsFormArray.clear();
    this.investmentsList.forEach((account: AccountFormObject, index) => {
      this.investmentsFormArray.push(
        new FormGroup({
          accountName: new FormControl(account.accountName),
          accountId: new FormControl(account.accountId),
          holdings: new FormArray([]),
          allHoldingsIncluded: new FormControl(false),
          someHoldingsIncluded: new FormControl(false),
          isParticipantAccount: new FormControl(account.isParticipantAccount),
        }),
      );

      account?.holdings?.forEach((holding: HoldingFormObject) => {
        this.getAccountHoldings(index).push(
          new FormGroup({
            holdingName: new FormControl(holding.holdingName),
            holdingId: new FormControl(holding.holdingId),
            holdingSymbol: new FormControl(holding.holdingSymbol),
            holdingBalance: new FormControl(holding.holdingBalance),
            isHoldingSelected: new FormControl(false),
          }),
        );
      });
    });
  }

  navigateToCreateGroup(): void {
    this.resetSuccessBanners();
    this.router.navigate(['managegroups/create']);
  }

  resetSuccessBanners(): void {
    this.showCreationSuccessBanner = false;
    this.showModifySuccessBanner = false;
    this.showDeleteSuccessBanner = false;
    this.pwViewsFormsService.showCreationSuccessBanner = false;
    this.pwViewsFormsService.showModifySuccessBanner = false;
    this.pwViewsFormsService.showDeleteSuccessBanner = false;
  }

  navigateToEditGroup(): void {
    this.resetSuccessBanners();
    this.router.navigate(['managegroups/edit'], {
      queryParams: { clientViewId: this.activeAccountGroupViewId },
    });
  }
}
