<div class="process-flow-pattern main-container container-fluid gx-0">
  <main>
    <div id="viewsHome">
      <ng-container *ngTemplateOutlet="mainContent"></ng-container>
    </div>
  </main>
</div>

<ng-template #mainContent>
  <div class="main-container__content-header container">
    <div class="content-header__exit-link c11n-text-md link" *ngIf="showExitLink">
      <a
        c11n-link
        [fontWeight]="'bold'"
        [iconName]="'remove'"
        [iconPosition]="'leading'"
        [variant]="'primary-independent'"
        [attr.href]="previousURL"
        >Exit</a
      >
    </div>
    <div class="content-header__back-link c11n-text-md link" *ngIf="showBackLink">
      <a
        #back
        c11n-link
        role="link"
        tabindex="0"
        [fontWeight]="'bold'"
        [iconName]="'link-left'"
        [iconPosition]="'leading'"
        [variant]="'primary-independent'"
        (click)="cancelFormChanges()"
        >Back</a
      >
    </div>
    <div
      role="heading"
      aria-level="1"
      class="content-header__title c11n-text-3xl-headline c11n-color-ink-100"
      #title
    >
      {{ pageTitle }}
    </div>
  </div>
  <div class="main-container__body container">
    <ng-container *ngIf="isLoading$ | async">
      <c11n-spinner
        [size]="'medium'"
        [colorMode]="'on-light'"
        [spinnerStatus]="'Loading...'"
        class="mx-auto"
      ></c11n-spinner
    ></ng-container>

    <ng-container *ngIf="isFailure$ | async">
      <c11n-banner
        [size]="'small'"
        [hasCloseControl]="false"
        [hasDetails]="false"
        [variant]="'error'"
      >
        <div role="alert" aria-live="assertive" aria-atomic="true" class="c11n-text-md--crop">
          {{ appContent.ACCOUNT_GROUPING_DATA_FAILURE }}
        </div>
      </c11n-banner>
    </ng-container>
  </div>

  <ng-container *ngIf="isSuccess$ | async">
    <router-outlet></router-outlet>
  </ng-container>
</ng-template>

<app-cancel-modal #cancelModalDialog></app-cancel-modal>
